/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createBusiness,
  deleteBusiness,
  deleteBusinessLocation,
  listBusinessLocation,
  updateBusiness,
} from '@digistaff/business';
import {Button, Stack, TextField, Typography} from '@mui/material';
import {nanoid} from 'nanoid';
import {ChangeEvent, useEffect, useState} from 'react';
import {Confirm} from '../../common/Alerts/Confirm';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {app} from '../../providers/Account';
import {ToastStruct} from '../../types/types';
import {
  BusinessLocation,
  BusinessStruct,
  emptyBusiness,
  emptyLocation,
} from './BusinessData';
import {BusinessRow} from './BusinessRow';

export interface ManageProps {
  open: boolean;
  handleClose: () => void;
  data: null | any;
  reload: () => void;
  displayToast: (data: ToastStruct) => void;
}
export const ManageBusiness = ({
  open,
  handleClose,
  data,
  reload,
  displayToast,
}: ManageProps) => {
  const [state, setState] = useState<BusinessStruct>(emptyBusiness);
  const [locations, setLocations] = useState<BusinessLocation[]>([
    {...emptyLocation, id: nanoid()},
  ]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState('');
  const [message, setMessage] = useState('');
  const [showLocations, setShowLocations] = useState(false);
  const [businessId, setBusinessId] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [markupRate, setMarkupRate] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setBusinessId(data.id);
      setState(data);
      setMarkupRate(data.markup_rate);
      setShowLocations(true);
      setLocations(data.nestListBusinessLocation);
    }
  }, [data]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setMarkupRate('');
      setError('');

      return;
    }

    if (inputValue === '-' || inputValue === '.' || inputValue === '-.') {
      setMarkupRate(inputValue);
      setError('');
      return;
    }

    // Check for valid number format including intermediate states
    if (!/^-?\d*\.?\d{0,2}$/.test(inputValue)) {
      // If more than 2 decimal places, just return without updating
      if (inputValue.includes('.')) {
        const [, decimal] = inputValue.split('.');
        if (decimal && decimal.length > 2) {
          return;
        }
      }
      setError('Please enter a valid number');
      setIsError(true);
      return;
    }

    setMarkupRate(inputValue);

    // Only validate complete numbers
    if (inputValue.endsWith('.')) {
      setError('');
      setIsError(false);
      return;
    }

    // Check if the input contains a decimal point
    if (!inputValue.includes('.')) {
      setError('Please enter a decimal number');
      setIsError(true);
      return;
    }

    const number = parseFloat(inputValue);
    if (isNaN(number)) {
      setError('Please enter a valid number');
      setIsError(true);
    } else {
      setIsError(false);
      setError('');
    }
  };

  const addLocation = () => {
    setLocations([
      ...locations,
      {...emptyLocation, id: nanoid(), business_id: state.id},
    ]);
  };

  const deleteLocation = (id: string) => {
    const newLocations = locations.filter((loc: any) => loc.id !== id);
    setLocations(newLocations);
  };

  const updateLocation = (id: string, newData: BusinessLocation) => {
    const index = locations.findIndex(loc => loc.id === id);
    const newLocations = [...locations];
    newLocations[index] = newData;
    setLocations(newLocations);
  };

  const validForm = () => {
    const newErrors: any = {};
    if (state.name.length === 0) newErrors.name = 'Required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddBusiness = () => {
    setShowConfirm(false);
    if (validForm()) {
      createBusiness(app, {name: state.name, markup_rate: markupRate})
        .then(res => {
          setBusinessId(res.data.createBusiness.id);
          setShowLocations(true);
          displayToast({
            severity: 'success',
            text: `Business ${state.name} has been created, you can now add business locations`,
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => reload());
    }
  };

  const handleDeleteBusiness = async () => {
    const result = await listBusinessLocation(app, {
      filter: {business_id: state.id},
    });

    const locationCount = result.data.listBusinessLocations.length;

    if (locationCount > 0) {
      setShowConfirm(false);
      displayToast({
        severity: 'info',
        text: 'Please delete all business locations before deleting a business',
      });
    } else {
      deleteBusiness(app, state.id)
        .then(res => {
          const locationsToDelete =
            res.data.deleteBusiness.nestListBusinessLocation;
          locationsToDelete.forEach((loc: any) => {
            deleteBusinessLocation(app, loc.id).catch(err => console.log(err));
          });
          setShowConfirm(false);
          handleModalClosed();
          displayToast({
            severity: 'success',
            text: `Business ${state.name} has been deleted`,
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => reload());
    }
  };

  const handleUpdateBusiness = () => {
    setShowConfirm(false);
    if (validForm()) {
      updateBusiness(app, state.id, {name: state.name, markup_rate: markupRate})
        .then(() => {
          displayToast({
            severity: 'success',
            text: `Business ${state.name} has been updated`,
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => reload());
    }
  };

  const manageConfirm = (type: string) => {
    setAction(type);
    switch (type) {
      case 'ADD':
        setMessage('Are you sure you want to add this new business?');
        setShowConfirm(true);
        break;
      case 'UPDATE':
        setMessage('Are you sure you want to update this business?');
        setShowConfirm(true);
        break;
      case 'DELETE':
        setMessage(`Are you sure you want to delete ${state.name}?`);
        setShowConfirm(true);
        break;
      default:
        console.log('Error. Unknown confirmation option provided');
    }
  };

  const handleAction = () => {
    switch (action) {
      case 'ADD':
        handleAddBusiness();
        break;
      case 'UPDATE':
        handleUpdateBusiness();
        break;
      case 'DELETE':
        handleDeleteBusiness();
        break;
      default:
        console.log('Error. Unknown action provided');
    }
  };

  const handleModalClosed = () => {
    setState(emptyBusiness);
    handleClose();
  };

  return (
    <>
      <GlobalModal
        open={open}
        onClose={handleModalClosed}
        defaultHeader={true}
        title={data !== null ? `Edit ${data.name}` : 'Add Business'}
        width="50vw"
        height="90vh"
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="column" spacing={2}>
            <TextField
              label="Business Name"
              size="small"
              fullWidth
              error={'name' in errors}
              helperText={errors.name}
              value={state.name}
              onChange={event => {
                setState({...state, name: event.target.value});
              }}
            />

            <TextField
              fullWidth
              size="small"
              label="Enter a Markup Rate"
              variant="outlined"
              value={markupRate}
              onChange={handleChange}
              error={!!error}
              helperText={error}
              placeholder="e.g., 3.14"
            />

            {data !== null && (
              <Stack direction="row" spacing={1}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => manageConfirm('UPDATE')}
                  disabled={state.name === '' || !markupRate || isError}
                >
                  Update
                </Button>
                <Button
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={() => manageConfirm('DELETE')}
                >
                  Delete
                </Button>
              </Stack>
            )}
            {data === null && (
              <Button
                variant="outlined"
                onClick={() => manageConfirm('ADD')}
                disabled={state.name === '' || !markupRate || isError}
              >
                Save
              </Button>
            )}
          </Stack>
          <Typography>
            You will need to add at least one location after you save the
            business if you want to create a shift under the selected business
          </Typography>
          {showLocations && (
            <>
              {locations?.map((location: any, index: number) => {
                return (
                  <BusinessRow
                    index={index}
                    key={location.id}
                    locationId={location.id}
                    businessId={businessId}
                    data={location}
                    deleteLocation={deleteLocation}
                    updateLocation={updateLocation}
                    displayToast={displayToast}
                  />
                );
              })}
              <Button variant="outlined" onClick={addLocation}>
                Add Location
              </Button>
            </>
          )}
        </Stack>
      </GlobalModal>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirmed={handleAction}
        text={message}
      />
    </>
  );
};
