/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import PhoneInput from 'react-phone-number-input';
import {ManageLogo} from './ImageSelect/ManageLogo';
import {ChangeEvent, useEffect, useState} from 'react';
import {AutoComplete} from '../../common/AutoComplete/AutoComplete';
import {app} from '../../providers/Account';
import {getTenant, updateTenant} from '@digistaff/business';
import {Confirm} from '../../common/Alerts/Confirm';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import {useFileUpload} from '../../common/hooks/useFileUpload';
import {Person} from '@mui/icons-material';

export const GeneralView = () => {
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [state, setState] = useState<any>({
    logo: '',
    name: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    hst_number: '',
    city: '',
    postal: '',
    province: '',
    street: '',
    country: '',
  });

  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();

  const handleUpdateTenant = async () => {
    setShowConfirm(false);
    setIsLoading(true);

    let uploaded: any = null;
    if (fileSelected) {
      uploaded = await handleFileUpload('tenant_logo');
    }

    if (app.tenantKey) {
      updateTenant(app, app.tenantKey, {
        ...state,
        logo: fileSelected ? uploaded.data.result.Location : state.logo,
      })
        .then(() =>
          setToastData({
            ...toastData,
            severity: 'success',
            text: 'Tenant information has been updated',
          })
        )
        .catch(err =>
          setToastData({
            ...toastData,
            severity: 'error',
            text: err,
          })
        )
        .finally(() => setIsLoading(false));
      setShowToast(true);
    }
  };

  useEffect(() => {
    if (app.tenantKey) {
      getTenant(app, app.tenantKey.toString())
        .then(res => {
          const tenant = res.data.getTenant;
          setState({
            logo: tenant.logo,
            name: tenant.name,
            contact_name: tenant.contact_name,
            contact_email: tenant.contact_email,
            contact_phone: tenant.contact_phone,
            hst_number: tenant.hst_number,
            city: tenant.city,
            postal: tenant.postal,
            province: tenant.province,
            street: tenant.street,
            country: tenant.country,
          });
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  }, []);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeHandler(event);
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target) {
          setState({...state, logo: e.target.result as string});
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <MainViewContainer title="General Information">
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Stack spacing={4} width="60%">
            <Stack spacing={1}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                {state.logo ? (
                  <Tooltip title="Current Logo">
                    <Box
                      component="img"
                      sx={{
                        height: 'auto',
                        width: '9rem',
                        border: 'solid 1px black',
                      }}
                      alt="Current Logo"
                      src={state.logo}
                      display="flex"
                      alignItems="center"
                    />
                  </Tooltip>
                ) : (
                  <Person sx={{fontSize: '8em', color: 'gray'}} />
                )}
              </Box>
              {/* <ManageLogo /> */}
              <OutlinedInput type="file" onChange={handleImageChange} />
              <TextField
                label="Tenant Name"
                size="small"
                value={state.name}
                onChange={event =>
                  setState({...state, name: event.target.value})
                }
              />
            </Stack>
            <Stack spacing={1}>
              <TextField
                label="Contact Name"
                size="small"
                value={state.contact_name}
                onChange={event =>
                  setState({...state, contact_name: event.target.value})
                }
              />
              <TextField
                label="Contact Email"
                size="small"
                value={state.contact_email}
                onChange={event =>
                  setState({...state, contact_email: event.target.value})
                }
              />
              <PhoneInput
                placeholder="Contact Phone Number"
                international
                countryCallingCodeEditable
                limitMaxLength
                defaultCountry="CA"
                value={state.contact_phone}
                onChange={value => {
                  setState({...state, contact_phone: value});
                }}
              />
            </Stack>

            <Stack spacing={1}>
              <AutoComplete
                placeHolder="Tenant Address"
                initialValue={`${state.street}, ${state.city}, ${state.province} ${state.postal}, ${state.country}`}
                onChange={(address: any) => {
                  setState({
                    ...state,
                    city: address.city,
                    postal: address.postal,
                    province: address.province,
                    street: address.street,
                    country: address.country,
                  });
                }}
              />
              <TextField
                label="HST Number"
                size="small"
                value={state.hst_number}
                onChange={event =>
                  setState({...state, hst_number: event.target.value})
                }
              />
            </Stack>

            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => setShowConfirm(true)}
              >
                {isLoading ? 'Loading...' : 'Update'}
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        text="Are you sure you want to update your tenant information?"
        handleConfirmed={handleUpdateTenant}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
